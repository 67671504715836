<script setup>
import {
    BookmarkIcon,
    CloudArrowDownIcon,
    HomeIcon,
    MagnifyingGlassIcon,
    UserCircleIcon,
} from '@heroicons/vue/24/outline'
import {Link, usePage} from "@inertiajs/vue3";
import {useWpaInstallStore} from "@/Stores/WpaInstallStore";
import { SwipeModal } from '@takuma-ru/vue-swipe-modal';
import {ref} from "vue";
import {useAnalyticsStore} from "@/Stores/AnalyticsStore.js";

const pwaInstallStore = useWpaInstallStore();
const showTelegramConnect = ref(false);
const analyticsStore = useAnalyticsStore();

const telegramIconClick = () => {
	analyticsStore.sendAnalyticsEvent('telegram_icon_click', {
		user_id: usePage().props.auth.user ? usePage().props.auth.user.id : null,
	})
}
</script>

<template>
	<nav class="max-w-xl mx-auto flex justify-evenly">
		<Link :class="{'animate-selected' : route().current('homepage')}" :href="route('homepage')" aria-label="Homepage"
		      cache-for="30s"
		      class="p-4 w-full h-full flex items-center justify-center text-dark-300 " prefetch="mount">
			<HomeIcon class="h-8"/>
		</Link>

		<Link :class="{'animate-selected' : route().current('discover')}" :href="route('discover')" aria-label="Discover Posts and Creators"
		      cache-for="5m"
		      class="p-4 w-full h-full flex items-center justify-center text-dark-300 " prefetch="mount">
			<MagnifyingGlassIcon class="h-8"/>
		</Link>

		<button v-if="pwaInstallStore.enabled" aria-label="Download App"
		        class=" p-4 w-full h-full flex items-center justify-center text-dark-300 "
		        @click="pwaInstallStore.showInAppInstallPromotion('Menu Bounce - Green')">
			<CloudArrowDownIcon class="h-8 text-green-600 animate-bounce"/>
		</button>

		<Link :class="{'animate-selected' : route().current('collection.index')}" :href="route('collection.index')" aria-label="Collections"
		      cache-for="5m"
		      class="p-4 w-full h-full flex items-center justify-center text-dark-300 " prefetch="mount">
			<BookmarkIcon class="h-8"/>
		</Link>

		<a v-if="!usePage().props.telegram.connected" :href="route('telegram.connect')" aria-label="Connect to Telegram Bot" class="p-4 w-full h-full flex items-center justify-center text-blue-500 "
		   target="_blank" @click="telegramIconClick">
			<img alt="Telegram Logo" class="h-8" src="/images/telegram.svg" />
		</a>

		<Link v-if="usePage().props.auth.user" :class="{'animate-selected' : route().current('profile.index')}" :href="route('profile.index')"
		      aria-label="My Account" cache-for="5m"
		      class="p-4 w-full h-full flex items-center justify-center text-dark-300 " prefetch="mount">
			<UserCircleIcon class="h-8"/>
		</Link>
		<Link v-else :class="{'animate-selected' : route().current('login')}" :href="route('login')" aria-label="Login/Register"
		      cache-for="5m"
		      class="p-4 w-full h-full flex items-center justify-center text-dark-300" prefetch="mount">
			<UserCircleIcon class="h-8"/>
		</Link>
	</nav>


	<SwipeModal key="telegram" v-model="showTelegramConnect" class="exclude-poppit" snapPoint="75vh">
		<div class="max-w-xl w-full p-4 mx-auto exclude-poppit">
			<div class="relative w-full flex flex-col justify-center items-center mb-8">
				<img alt="Telegram Connect" class="w-full rounded-lg" src="/images/telegram-connect.png" />
				<div class="rounded-full absolute bg-white p-2 border-blue-500/75 border-4 -bottom-6 overflow-hidden">
				<img alt="Telegram Logo" class="w-8 h-8" src="/images/telegram.svg" />
				</div>
			</div>
			<div class="space-y-4" v-html="usePage().props.settings.telegram.connect_panel.message"></div>
			<div class="my-8 justify-center flex">
				<a :href="route('telegram.connect')"
				   class="inline-flex items-center justify-center px-4 py-2 bg-blue-600 border border-transparent rounded-md font-semibold text-white uppercase tracking-widest hover:bg-blue-500 active:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-dark-800 transition ease-in-out duration-150"
				   target="_blank" @click="telegramConnectClick">
					<span v-text="usePage().props.settings.telegram.connect_panel.button"></span>
				</a>
			</div>
		</div>
	</SwipeModal>
</template>
